export default function Publikationen() {
  return (
    <>
      <h2 className="m-2 p-2">Publikationen</h2>

      <h4 className="m-2 p-2">Musikwissenschaftliche Publikationen</h4>
      <p className="m-2 p-2">
        {" "}
        &raquo;Musiktheater als inszenierte Zeit. Zum Zusammenhang zwischen
        dramaturgischer und harmonisch-kontrapunktischer Zeitgestaltung in
        Mozarts Bühnenwerken&laquo;, in: <i>Gegliederte Zeit</i>, 15.
        Jahreskongress der Gesellschaft für Musiktheorie Berlin 2015, hg. von
        Marcus Aydintan, Florian Edler, Roger Graybill und Laura Krämer,
        Hildesheim: Olms, retrodigitalisiert unter <br></br>
        <a
          href="https://doi.org/10.31751/p.177"
          className="text-decoration-none clickable"
        >
          https://doi.org/10.31751/p.177
        </a>{" "}
        (2020)
      </p>

      <p className="m-2 p-2">
        &raquo;Polyphonie als Mittel der dramatischen Kunst bei Wolfgang Amadeus
        Mozart. Zu kanonischen Satztechniken in den Bühnenwerken der Wiener
        Schaffensperiode&laquo;, in: <i>Kombinatorik und Spiel</i>, Wege
        musikalischen Denkens Festschrift für Stefan Prey, hg. von Thomas
        Fesefeldt, Andreas Ickstadt, Ariane Jeßulat u. a., Berlin: Universität
        der Künste
        <br></br>
        <a
          href="https://doi.org/10.25624/kuenste-1832"
          className="text-decoration-none clickable"
        >
          https://doi.org/10.25624/kuenste-1832
        </a>{" "}
        (2022)
      </p>

      <h4 className="m-2 p-2">Pädagogische Publikationen</h4>
      <p className="m-2 p-2">
        &raquo;Musiktheorie zum Klingen gebracht. Wege in die
        künstlerisch-pädagogische Praxis&laquo;, in:{" "}
        <i>›Klang‹: Wundertüte oder Stiefkind der Musiktheorie</i>. 16.
        Jahreskongress der Gesellschaft für Musiktheorie Hannover 2016 (GMTH
        Proceedings 2016) <br></br>
        <a
          href="https://doi.org/10.31751/p.21"
          className="text-decoration-none clickable"
        >
          https://doi.org/10.31751/p.21
        </a>{" "}
        (2020)
      </p>

      <p className="m-2 p-2">
        &raquo;Klassikadaptionen in Rock, Pop und Filmmusik. Ihr analytisches
        Potential für den schulischen Musikunterricht&laquo;, in:
        <i>
          {" "}
          Populäre Musik und ihre Theorien. Begegnungen – Perspektivwechsel –
          Transfers
        </i>
        . 17. Jahreskongress der Gesellschaft für Musiktheorie und 27.
        Arbeitstagung der Gesellschaft für Popularmusikforschung Graz 2017 (GMTH
        Proceedings 2017) <br></br>
        <a
          href="https://doi.org/10.31751/p.52"
          className="text-decoration-none clickable"
        >
          https://doi.org/10.31751/p.52
        </a>{" "}
        (2021)
      </p>

      <p className="m-2 p-2">
        &raquo;Musik verstehen ohne Noten? Notationskonzepte für Schule und
        Musikschule&laquo;, in:
        <i>
          {" "}
          Notation. Schnittstelle zwischen Komposition, Interpretation und
          Analyse
        </i>
        . 19. Jahreskongress der Gesellschaft für Musiktheorie (=GMTH
        Proceedings 2019), hg. von Philippe Kocher, 317–332. Engelhardt, Roman
        und Elke Reichel
        <br></br>
        <a
          href="https://doi.org/10.31751/p.269"
          className="text-decoration-none clickable"
        >
          https://doi.org/10.31751/p.269
        </a>{" "}
        (2023)
      </p>

      <p className="m-2 p-2">
        &raquo;Vom Song zum Rondo - zwei Unterrichtsideen für die
        Unterstufe&laquo;, in:
        <i> musikunterricht aktuell</i>. Zeitschrift des Bundesverbandes
        Musikunterricht e.V., Ausgabe 18/2023, 8-12.
        <br></br>
        <a
          href="https://www.bmu-musik.de/publikationen/magazin-musikunterricht-aktuell/"
          className="text-decoration-none clickable"
        >
         https://www.bmu-musik.de
        </a>{" "}
        (2023)
      </p>
    </>
  );
}
