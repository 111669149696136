export default function LehreDetail({
  showMe,
  setShowme,
  trigger,
  title,
  text,
}) {
  return (
    <>
      <p
        className="m-2 p-2  text-secondary clickable"
        onClick={() => {
          if (showMe === trigger) {
            setShowme("");
          } else {
            setShowme(trigger);
          }
        }}
      >
        {title}
      </p>

      {showMe === trigger && (
        <>
          <p className="m-2 p-2" style={{fontSize:"90%"}}>{text}</p>
        </>
      )}
    </>
  );
}
