export default function Datenschutz() {
  return (
    <>
      <h2 className="m-2 p-2"> Datenschutz</h2>

      <h4 className="m-2 p-2">Ansprechpartnerin - Datenschutzbeauftragte</h4>
      <p className="m-2 p-2">
        auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:
      </p>
      <ul className="justify-content-end list-unstyled m-2 p-2">
        <li>Elke Reichel</li>
        <li>
          <a
            href="mailto:elke-reichel@freenet.de"
            className="text-decoration-none"
          >
            elke-reichel@freenet.de
          </a>
        </li>
        <li>+49 351 31234557</li>
        <li>Barteldesplatz 1</li>
        <li>01309 Dresden</li>
      </ul>

      <h4 className="m-2 p-2">Auszug Ihrer Rechte nach DSGVO</h4>

      <ul className="list-unstyled m-2 p-2">
        <li>
          Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht
          auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten,
          die Verarbeitungszwecke, die Kategorien der verarbeiteten
          personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
          gegenüber denen Ihre Daten offengelegt wurden oder werden, die
          geplante Speicherdauer bzw. die Kriterien für die Festlegung der
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
          Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung,
          Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn
          diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer
          automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftige Informationen über die involvierte Logik und die Sie
          betreffende Tragweite und die angestrebten Auswirkungen einer solchen
          Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien
          gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
          bestehen;
        </li>
        <li>
          Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf
          unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder
          Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;
        </li>
        <li>
          Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die
          Löschung Ihrer personenbezogenen Daten bei Vorliegen der
          Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht
          besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur
          Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
          Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie
          haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, solange die von Ihnen
          bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine
          Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und
          stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen,
          wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen, nachdem wir diese Daten nach
          Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
          Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht
          feststeht, ob unsere berechtigten Gründe überwiegen;
        </li>
        <li>
          Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf
          Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
          dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
          Empfängern, denen die Sie betreffenden personenbezogenen Daten
          offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
          Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
          sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
          verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet
          zu werden.
        </li>
        <li>
          Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das
          Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
          in einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
          verlangen, soweit dies technisch machbar ist;
        </li>
        <li>
          Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:
          Sie haben das Recht, eine einmal erteilte Einwilligung in die
          Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
          widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
          unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
          Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
          kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt;
        </li>
        <li>
          Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind,
          dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
          gegen die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen
          verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht
          auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des
          Ortes des mutmaßlichen Verstoßes.
        </li>

        <li>
          Auszug Ihrer Rechte nach DSGVO. Diese Website verarbeitet keine
          personenbezogenen Daten. Die folgenden Angaben sind für den Fall
          bestimmt, dass Sie mich per Mail, Telefon oder schriftlich
          kontaktieren.
        </li>
      </ul>
      <h4 className="m-2 p-2">Cookies</h4>

      <p className="m-2 p-2">
        Diese Webseite verwendet keine so genannten „Cookies“.
      </p>
    </>
  );
}
