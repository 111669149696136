import elkereichel from "../pics/elkereichel.png";
import { Image } from "react-bootstrap";
import SideKey from "./SideKey";

export default function Index2() {
  return (
    <>
      <div className="row text-center">
        <div className="d-flex flex-column align-items-center">
          <div className="mx-auto d-flex">
            <a
              href="/impressum"
              className="link-secondary m-1 text-decoration-none clickable"
            >
              Impressum
            </a>
            <a
              href="/datenschutz"
              className="link-secondary m-1 text-decoration-none clickable"
            >
              Datenschutz
            </a>
          </div>

          <h1>Elke Reichel</h1>
          <p>Musiktheorie - Hörerziehung - Analyse</p>
          <a
            href="/artisticcitizenship"
            className="text-center text-decoration-none clickable"
            style={{ fontSize: "115%", zIndex: 1 }}
          >
            {" "}
            Artistic Citizenship{" "}
          </a>
        </div>
      </div>
      <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-2 order-md-1" >
          <SideKey />
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-1 order-md-2">
          
            <Image
              id = "elke"
              src={elkereichel}
              fluid
              className="m-2 p-2"
              style={{
                height: "auto",
                maxHeight: "300px",
                objectFit: "contain",
                transform: "translateY(-40px)",
                
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
