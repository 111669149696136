export default function ArtisticCitizenship() {
  return (
    <> 
      <h2 className="m-2 p-2">Artistic Citizenship</h2>
      <p className="m-2 p-2">
        Der Begriff ist im deutschsprachigen Raum recht neu, nicht jedoch das,
        was er beschreibt. Meine Arrangements und Kompositionen verstehen sich
        als Werke, die mit ihrem sozialen Kontext untrennbar verwoben sind – sie
        entstehen aus konkreten Anlässen für das Miteinander von Menschen mit
        sehr unterschiedlichen persönlichen und künstlerischen Voraussetzungen.
      </p>
      <a href="/projekte" className="text-decoration-none m-2 p-2">Projekte</a>

     
    </>
  );
}
