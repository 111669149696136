import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createContext, useState} from "react";

import Index2 from "./components/Index2";
import Vita from "./components/Vita";
import Lehre from "./components/Lehre";
import Forschung from "./components/Forschung";
import Publikationen from "./components/Publikationen";
import Header from "./components/Header";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import ArtisticCitizenship from "./components/ArtisticCitizenship";
import Projekte from "./components/Projekte";

import "./parallax.css";


export const MainContext = createContext();

function App() {
  const [hasPw, setHasPw] = useState(window.localStorage.getItem("PW") || "");

  return (
    <>
      <BrowserRouter>
        <MainContext.Provider
          value={{
            hasPw,
            setHasPw,
          }}
        >
          <div className="container p-0 parallax-bg">
            <div className="parallax">
              <div className="book-top"> </div>
              <div className="book-bot"></div>
              <div className="book-bot-high"></div>
              <div className="container inner-container m-0 pt-4 pb-4">
                <div className="container m-0 p-0">
                  <Header />
                  <div className="container m-2 p-2 mt-0 pt-0" id="content">
                    <Routes>
                      <Route path="/" element={<Index2 />} />
                      <Route path="/vita" element={<Vita />} />
                      <Route path="/lehre" element={<Lehre />} />
                      <Route path="/forschung" element={<Forschung />} />
                      <Route
                        path="/publikationen"
                        element={<Publikationen />}
                      />
                      <Route path="/impressum" element={<Impressum />} />
                      <Route path="/datenschutz" element={<Datenschutz />} />
                      <Route
                        path="/artisticcitizenship"
                        element={<ArtisticCitizenship />}
                      />
                      <Route path="/projekte" element={<Projekte />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
