import FiveLines from "../svgs/FiveLines";

export default function SideKey() {
  return (
    <>
      <div
        className="d-flex justify-content-center "
        style={{ marginTop: "-25px" }}
      >
        <div
          className="container index-key"
          style={{ position: "relative", margin: "-10px" }}
        >
          <div
            style={{
              position: "absolute",
              top: "83px",
              left: "135px",
              fontSize: "115%",
            }}
          >
            <a href="/vita" className="text-decoration-none clickable">
              Vita
            </a>
          </div>
          <div
            style={{
              position: "absolute",
              top: "140px",
              left: "150px",
              fontSize: "115%",
            }}
          >
            <a
              href="/lehre"
              className="text-decoration-none clickable"
              style={{}}
            >
              Lehre
            </a>
          </div>
          <div
            style={{
              position: "absolute",
              top: "195px",
              left: "170px",
              fontSize: "115%",
            }}
          >
            <a href="/forschung" className="text-decoration-none clickable">
              Forschung
            </a>
          </div>
          <div
            style={{
              position: "absolute",
              top: "255px",
              left: "180px",
              fontSize: "115%",
            }}
          >
            <a href="/publikationen" className="text-decoration-none clickable">
              Publikationen
            </a>
          </div>
          <div
            style={{
              position: "absolute",
              top: "320px",
              left: "170px",
              fontSize: "115%",
            }}
          >
            <a href="/projekte" className="text-decoration-none clickable">
              Projekte
            </a>
          </div>

          <FiveLines size={350}/>
        </div>
      </div>
    </>
  );
}
