import { useEffect } from "react";

export default function FiveLines({ size }) {
  const width = size;
  const height = (size * 297) / 210;
  /* viewBox="0 0 210 297" */

  function drawLines() {
    let paths = document.querySelectorAll("svg path");

    paths.forEach((item, index) => {
      let pathLen = item.getTotalLength();

      if (pathLen >= 200) {
        item.setAttribute("stroke-dasharray", pathLen);

        item.setAttribute("stroke-dashoffset", pathLen);

        item.innerHTML =
          "<animate attributeName = 'stroke-dashoffset' begin = '0s' dur= '1.0s' to='0' fill='freeze' />";
      }

      
    });
  }

  useEffect(() => {
    drawLines();
  }, []);

  return (
    <>
      <svg
        width={width}
        height={height}
        version="1.1"
        viewBox="0 100 130 110"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" stroke="#000" strokeWidth="0.6" >
          <path
            d="m26.379 163.59c-0.22552-1.4539-0.22552-2.9426 0-4.3965 0.30809-1.9862 1.0436-3.9129 2.196-5.5597 1.1525-1.6468 2.7259-3.0072 4.5521-3.8468 2.3407-1.076 5.0297-1.2689 7.5661-0.81796 3.0868 0.54876 5.9876 2.0319 8.3074 4.1409 2.1529 1.9573 3.8074 4.438 4.9077 7.1316 1.3296 3.2546 1.8574 6.8151 1.687 10.327-0.09688 1.9966-0.41821 3.9907-1.0736 5.8791-1.3093 3.7726-3.9297 7.0117-7.106 9.4321-3.5769 2.7257-7.9325 4.4808-12.423 4.7288-4.6145 0.25489-9.2427-1.0813-13.241-3.3996-4.7187-2.7363-8.6416-6.8745-10.966-11.809-2.2106-4.6938-2.9491-10.024-2.4028-15.183 0.64669-6.1067 3.0275-11.905 5.9046-17.33 2.5641-4.8348 5.537-9.4429 8.1285-14.263 2.7621-5.1376 5.0841-10.504 7.106-15.976 1.7675-4.7827 3.3164-9.6889 3.8785-14.757 0.54685-4.9302 0.14246-9.9615-1.1568-14.749-0.58958-2.1724-1.3687-4.31-2.536-6.2346-1.1673-1.9247-2.7408-3.6354-4.6938-4.7546-1.5638-0.89623-3.3591-1.3979-5.1616-1.39-1.8024 8e-3 -3.6068 0.53214-5.1048 1.5346-2.3578 1.5778-3.8305 4.2401-4.4761 7.0027-0.64558 2.7625-0.54272 5.6429-0.2956 8.4691 1.7853 20.417 10.766 39.382 18.364 58.417 8.3508 20.921 15.162 42.435 20.966 64.201 0.98834 3.7059 1.9525 7.4492 2.1689 11.278 0.21525 3.8081-0.33958 7.712-2.0243 11.134-1.5238 3.095-4.035 5.7835-7.2298 7.0852-2.2662 0.9234-4.816 1.1192-7.2062 0.59453-2.3902-0.52465-4.6134-1.762-6.3496-3.4864-2.4348-2.4182-3.8722-5.7411-4.3017-9.1457-0.24061-1.907-0.17898-3.8602 0.27413-5.7282 0.45311-1.868 1.304-3.6491 2.5455-5.1165 0.59453-0.70275 1.2804-1.3351 2.0672-1.8129 0.78679-0.47779 1.6778-0.79782 2.5961-0.86215 1.1638-0.0815 2.3528 0.26041 3.2955 0.94779s1.6318 1.7148 1.91 2.8479"
            strokeWidth="1.26458"
          ></path>

<path d="m80.341 104.75-79.745 0.15989"/>


<path d="m99.745 125.67c-5.4946 0.1446-99.633 0.0799-99.633 0.0799"/>


<path d="m.1996 146.91h111.51"/>

<path d="m110.7 169.2c-7.519-0.1446-.39 0-123.39 0"/>
  



  <path d="m0.4805 192.26h100.74"/>
  

         {/*  <path d="m0.4805 192.26h100.74" />
          <path d="m-0.056543 146.24h111.51" />
          <path d="m123.7 169.2c-7.519-0.1446-123.39 0-123.39 0" />
          <path d="m99.745 125.67c-5.4946 0.1446-99.633 0.0799-99.633 0.0799" />
          <path d="m80.341 104.75-79.745 0.15989" /> */}
        </g>
      </svg>
    </>
  );
}
