import { useState } from "react";
import LehreDetail from "./Lehre/LehreDetail";

export default function Lehre() {
  const [showMe, setShowme] = useState("");
  return (
    <>
      <h2 className="m-2 p-2">Lehre</h2>

      <p className="m-2 p-2">Musiktheorie, Harmonielehre, Tonsatz</p>
      <p className="m-2 p-2">Hörerziehung, Gehörbildung</p>
      <p className="m-2 p-2">Musikalische Analyse / Hör- und Werkanalyse</p>
      <p className="m-2 p-2">
        Instrumentation / Instrumentieren und Arrangieren
      </p>
      <LehreDetail
        showMe={showMe}
        setShowme={setShowme}
        trigger={"komponieren"}
        title={
          "Elementares Komponieren und Arrangieren für Schule und Musikschule"
        }
        text={`Einen Kanon fürs Sommerfest schreiben, Filmmusik oder Klassik für
        ein Schul- oder Musikschulorchester arrangieren, mit klassischen und
        Orff-Instrumenten historische Tänze improvisieren, spielerisch mit
        Karten und Würfeln komponieren: der Wahlpflichtkurs soll
        Anwendungsmöglichkeiten von Tonsatz im schulischen Kontext
        vorstellen. Die vorgestellten Arbeitstechniken orientieren sind auf
        alle Altersstufen übertragbar.`}
      />

      <p className="m-2 p-2">Live-Arrangement / Improvisation</p>

      <p className="m-2 p-2">
        Musiktheaterkomposition für Schule und Musikschule
      </p>

      <LehreDetail
        showMe={showMe}
        setShowme={setShowme}
        trigger={"theorie"}
        title={"Integrative Musiktheorie"}
        text={`Filmmusik oder Klassik für ein Ensemble arrangieren, mit Anfängern und Fortgeschrittenen Kanons und historische Tänze improvisieren, Formenlehre humorvoll verständlich machen, Spiele für die Musiklehre (am Instrument oder vokal, mit Karten oder Würfeln) entwickeln… das Seminar soll dazu anregen, Musiktheorie kreativ in Ensemblearbeit, Instrumental- und Gesangsunterricht einzusetzen.`}
      />
      <br></br>

      <LehreDetail
        showMe={showMe}
        setShowme={setShowme}
        trigger={"film"}
        title={"Filmmusik"}
        text={`Musik für den Film hat in den vergangenen ca. 100 Jahren eigene Gesetzmäßigkeiten entwickelt, die sie von anderen Genres der Gegenwartsmusik abgrenzen. Gleichzeitig bedient sich Filmmusik absichtsvoll am stilistischen Repertoire aller Epochen und Genres.Wir fragen danach, wie kompositorische Mittel im Dienst der Filmdramaturgie eingesetzt werden,und warum Soundtracks längst vergessener Filme selbständig Karriere machen können. Die audiovisuelle Analyse bildet dabei den Schwerpunkt, ergänzt durch die Auseinandersetzung mit Partiturauszügen und satztechnische Übungen.`}
      />

      <p className="m-2 p-2">
        Fachdidaktik und Unterrichtspraxis für Gehörbildung und Musiktheorie
      </p>
      <p className="m-2 p-2">
        Fachdidaktik und Lehrpraxis der elementaren Musiktheorie
      </p>
    </>
  );
}
