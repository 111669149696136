export default function Vita() {
  return (
    <>
      <h2 className="p-2 m-2">Vita</h2>
      <h4 className="p-2 m-2">Schul- und Hochschulbildung</h4>

      {/*     <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2"> </p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Schulbesuch und Allgemeine Hochschulreife in Dresden
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        Schulbesuch und Allgemeine Hochschulreife in Dresden
      </p>

      {/*       <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">1991</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Studium der Kirchenmusik in Dresden, Hochschulabschluss
                Kirchenmusik (B-Examen)
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        Studium der Kirchenmusik in Dresden, <br></br> Hochschulabschluss
        Kirchenmusik (B-Examen), 1991
      </p>

      {/*    <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2"> Diplom 1995</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Studium der Instrumentalpädagogik (Hauptfach Orgel),
                Hochschule der Künste Berlin
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        Studium der Instrumentalpädagogik (Hauptfach Orgel), <br></br>{" "}
        Hochschule der Künste Berlin, Diplom 1995
      </p>

      {/*      <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">Diplom 2002</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Studium der Musikpädagogik, Hauptfach Musiktheorie, Universität
                der Künste Berlin
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        Studium der Musikpädagogik, Hauptfach Musiktheorie, <br></br>
        Universität der Künste Berlin, Diplom 2002
      </p>

      <h4 className="p-2 m-2">Ausgewählte Tätigkeiten</h4>

      {/*       <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">1991 - 1992</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Ev.-luth. Kirchgemeinde Wilsdruff / Sachsen, Kirchenmusikerin
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        Ev.-luth. Kirchgemeinde Wilsdruff / Sachsen, <br></br>
        Kirchenmusikerin (1991 - 1992)
      </p>

      {/*   <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">1996 - 2022</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                <a
                  href="https://www.hskd.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Heinrich-Schütz-Konservatorium der Landeshauptstadt Dresden
                </a>
                , Ensembleleiterin (1996-2020), Musikpädagogin (1996-2022)
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        <a
          href="https://www.hskd.de/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Heinrich-Schütz-Konservatorium der Landeshauptstadt Dresden, 
        </a><br></br>
        Ensembleleiterin (1996 - 2020) <br></br> Musikpädagogin (1996 - 2022)
        <br></br>Fachberaterin Musiktheorie und Grundstufe für die Musikschulen
        im Regierungsbezirk Dresden (2002 - 2004) <br></br>Fachberaterin
        Inklusion für die Musikschulen im Freistaat Sachsen (2004 - 2022)
      </p>

      {/*       <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2002 - 2004</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Fachberaterin Musiktheorie und Grundstufe für die Musikschulen
                im Regierungsbezirk Dresden
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/*       <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2004 - 2022</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                Fachberaterin Inklusion für die Musikschulen im Freistaat
                Sachsen
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/*  <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2012 - 2023</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                
                <a
                  href="https://www.hfmdd.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Hochschule für Musik Dresden
                </a>
                , Lehrbeauftragte für Musiktheorie und Gehörbildung{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        <a
          href="https://www.hfmdd.de/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Hochschule für Musik Dresden,
        </a><br></br>
        Lehrbeauftragte für Musiktheorie und Gehörbildung (2012 - 2023)
      </p>

      {/*   <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2014 - 2020</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                <a
                  href="https://www.hmt-leipzig.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Hochschule für Musik und Theater Leipzig
                </a>
                , Lehrbeauftragte Tonsatz am Institut für Musikpädagogik{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        <a
          href="https://www.hmt-leipzig.de/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Hochschule für Musik und Theater Leipzig, 
        </a><br></br>
        Lehrbeauftragte Tonsatz am Institut für Musikpädagogik (2014 - 2020)
      </p>

      {/*     <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">seit 2019</p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                <a
                  href="https://www.hfm-weimar.de/start"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Hochschule für Musik Weimar
                </a>
                , Lehrkraft für besondere Aufgaben Musiktheorie{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <p className="m-2 p-2">
        <a
          href="https://www.hfm-weimar.de/start"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Hochschule für Musik Weimar, 
        </a><br></br>
        Lehrkraft für besondere Aufgaben Musiktheorie (seit 2019)
      </p>
      <h4 className="m-2 p-2">Mitgliedschaften</h4>
      <p className="m-2 p-2">
        <a
          href="https://www.gmth.de/home.aspx"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Gesellschaft für Musiktheorie
        </a>
      </p>

      <p className="m-2 p-2">
        Gründungs- und Leitungsmitglied der
        <a
          href="https://www.gmth.de/arbeitsgemeinschaften/musikunterricht.aspx"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Arbeitsgemeinschaft Musikunterricht
        </a>
      </p>

      <p className="m-2 p-2">
        Mitglied der
        <a
          href="https://www.gmth.de/arbeitsgemeinschaften/gehoerbildung.aspx"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable"
        >
          Fachgemeinschaft Hörerziehung-Gehörbildung
        </a>
      </p>
    </>
  );
}
