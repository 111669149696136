export default function Forschung() {
  return (
    <>
      <h2 className="m-2 p-2">Forschungsinteressen</h2>
      <h4 className="m-2 p-2">
        Dissertationsprojekt: W. A. Mozarts Dramaturgie des Musiktheaters
      </h4>
      <p className="m-2 p-2">
        Die abendfüllenden Bühnenwerke aus Mozarts Wiener Schaffensperiode
        faszinieren mich seit meiner Kindheit. Die intensive Beschäftigung mit
        ihren dramaturgischen und kompositorischen Strukturen haben diese Werke
        für mich keineswegs entzaubert.
      </p>
      <p className="m-2 p-2">
        Gestützt auf Briefzeugnisse Mozarts und die musikwissenschaftliche
        Forschung beschreibe ich, wie der Komponist in aktiver
        Auseinandersetzung mit Traditionslinien des europäischen Theaters und
        Musiktheaters ein dramaturgisches Konzept entwickelt, das seine späten
        dramatischen Werke entscheidend prägt. Auf dieser Grundlage untersuche
        ich, wie Mozart eine Fülle harmonisch- kontrapunktischer, syntaktischer,
        melodischer und rhythmischer Mittel in den Dienst seiner dramatischen
        Konzeption und der Handlungslogik der Werke stellt. Im Mittelpunkt der
        Analyse stehen musikalische Aktionsszenen, die im Handlungsverlauf eine
        Schlüsselposition einnehmen.
      </p>
      <p className="m-2 p-2">
        Das Dissertationsprojekt wird an der{" "}
        <a
          href="https://hmtm.de/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable2"
        >
          Hochschule für Musik und Theater München
        </a>{" "}
        durch{" "}
        <a
          href="https://kaiser-ulrich.de/"
          className="text-decoration-none clickable"
          target="_blank"
          rel="noopener noreferrer"
        >
          Prof. Dr. Ulrich Kaiser
        </a>{" "}
        betreut.
      </p>
      <h4 className="m-2 p-2">Musiktheorie und Musikpädagogik</h4>
      <p className="m-2 p-2">
        Wie kann zeitgemäße Musiktheorie in Hochschule, Schule und Musikschule
        angemessen vermittelt werden, und wie kann sie die Entwicklung
        vielseitiger Laien- und Berufsmusiker*innen fördern? Diesen für meine
        Lehrtätigkeit zentralen Fragen gehe ich in Vorträgen, Workshops und
        Schriftbeiträgen nach. Dabei kommen mir Erfahrungen aus meiner
        langjährigen Tätigkeit am Heinrich-Schütz-Konservatorium Dresden zugute.
        Als Gründungsmitglied und eine Sprecherin der Arbeitsgemeinschaft
        Musikpädagogik der GMTH setze ich mich für den Austausch und die enge
        Zusammenarbeit mit Musikpädagog*innen aus Schule und Musikschule ein.
      </p>
      <h4 className="m-2 p-2">Popular- und Filmmusik</h4>
      <p className="m-2 p-2">
        Populäre Musik spielt im Diskurs der Musiktheorie eine untergeordnete
        Rolle, obwohl sie im Alltag omnipräsent und für viele künstlerische und
        pädagogische Berufsfelder von großer Bedeutung ist. Meine Beiträge z. B.
        zu Satzmodellen in Rock und Pop, zur Filmmusik Ennio Morricones und zu
        Open-Source-Musik verstehen sich als Anstoß zu einer Belebung des
        Fachdiskurses zu populären Musikstilen.
      </p>
    </>
  );
}
