import { useLocation } from "react-router-dom";
import FiveLines from "../svgs/FiveLines";

import { Navbar, Nav } from "react-bootstrap";

export default function Header() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/" ? (
        <>
          <Navbar bg="transparent" expand="lg" className="m-2 p-2 ml-5 pl-5">
            <Navbar.Brand href="/" className="ml-5 pl-5 mr-5 pr-5" style={{marginLeft : "25px"}}>
              <FiveLines size={70} className="ml-5 pl-5" />
              Elke Reichel
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                {location.pathname !== "/vita" && (
                  <Nav.Link className="link-secondary clickable" href="/vita">
                    Vita
                  </Nav.Link>
                )}
                {location.pathname !== "/lehre" && (
                  <Nav.Link className="link-secondary clickable" href="/lehre">
                    Lehre
                  </Nav.Link>
                )}
                {location.pathname !== "/forschung" && (
                  <Nav.Link className="link-secondary clickable" href="/forschung">
                    Forschung
                  </Nav.Link>
                )}
                {location.pathname !== "/publikationen" && (
                  <Nav.Link className="link-secondary clickable" href="/publikationen">
                    Publikationen
                  </Nav.Link>
                )}
                {location.pathname !== "/projekte" && (
                  <Nav.Link className="link-secondary clickable" href="/projekte">
                    Projekte
                  </Nav.Link>
                )}
                {location.pathname !== "/impressum" && (
                  <Nav.Link className="link-secondary clickable" href="/impressum">
                    Impressum
                  </Nav.Link>
                )}
                {location.pathname !== "/datenschutz" && (
                  <Nav.Link className="link-secondary clickable" href="/datenschutz">
                    Datenschutz
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </>
      ) : (
        <>
         {/*  <Navbar bg="transparent" expand="lg" className="m-2 p-2">
            <div className="mx-auto d-flex">
              <Nav.Link className="link-secondary m-1" href="/impressum">
                Impressum
              </Nav.Link>
              <Nav.Link className="link-secondary m-1" href="/datenschutz">
                Datenschutz
              </Nav.Link>
            </div>
          </Navbar> */}
        </>
      )}
    </>
  );
}
