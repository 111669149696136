export default function Projekte() {
  return (
    <>
      <h2 className="m-2 p-2"> Projekte</h2>
      <p className="m-2 p-2">
        Künstlerisches Handeln ist für mich untrennbar mit sozialem Handeln
        verbunden. Die meinen Projekten zugrunde liegende Idee lässt sich mit
        dem Konzept des{" "}
        <a
          href="/artisticcitizenship"
          className="text-decoration-none clickable"
        >
          Artistic Citizenship{" "}
        </a>{" "}
        beschreiben.
      </p>
      <h4 className="m-2 p-2">
        Arrangements und Kompositionen für Projektorchester und inklusive
        Ensembles
      </h4>
      <p className="m-2 p-2">
        Ich komponiere und arrangiere für das gemeinschaftliche Musizieren in
        heterogenen Gruppen, z. B. die Mitwirkenden und das Publikum eines
        Konzerts, die Schüler*innen einer Klasse, die Teilnehmer*innen eines
        Workshops oder die Mitglieder eines inklusiven Ensembles. Mein Anspruch
        beim Schreiben für solche Gruppen, die regelmäßig oder auch nur einmalig
        zusammenkommen, besteht darin, jedem und jeder Beteiligten eine dem
        jeweiligen Leistungsstand angemessene, aber für das Gelingen des Ganzen
        unverzichtbare Stimme zuzuweisen.
      </p>
      <h4 className="m-2 p-2">
        Komponieren für kooperative Musiktheaterprojekte
      </h4>
      <p className="m-2 p-2">
        Zwischen 2012 und 2022 unterrichtete ich in Dresden neben meiner
        Beschäftigung am Heinrich- Schütz-Konservatorium im Lehrauftrag
        zukünftige Musiklehrer*innen an der{" "}
        <a
          href="https://www.hfmdd.de/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none clickable2"
        >
          Hochschule für Musik Dresden
        </a>.{" "}
        An der Schnittstelle von Musikschule, Schule und Hochschule konnte ich
        zwischen 2014 und 2018 Musiktheaterprojekte realisieren, die von der
        Auswahl des Sujets über Textdichtung und Komposition bis zur
        Einstudierung und Aufführung reichten.
      </p>
      <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2014 bis 2015 </p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                <strong> Der Grüffelo</strong>
              </p>
              <p className="m-2 p-2">
                Musical nach Julia Donaldson und Axel Scheffler für
                Schüler*innen von{" "}
                <a
                  href=" https://www.122grundschule.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  122. Grundschule Dresden
                </a>{" "}
                und{" "}
                <a
                  href="https://www.hskd.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Heinrich-Schütz-Konservatorium Dresden
                </a>.{" "}
                Komposition im Team mit Studierenden der HfM Dresden
                Einstudierung und musikalische Leitung der Aufführungen: Elke
                Reichel
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2015 bis 2016 </p>
            </div>
            <div className="col-10">
              <p className="m-2 p-2">
                <strong>Die graue Stadt</strong>
              </p>
              <p className="m-2 p-2">
                Musical für Schüler*innen der{" "}
                <a
                  href=" https://www.122grundschule.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  122. Grundschule Dresden
                </a>.{" "}
                Textdichtung, Komposition, Einstudierung und musikalische
                Leitung der Aufführungen im Team mit Studierenden der HfM
                Dresden
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="container d-flex justify-content-center">
          <div className="row">
            <div className="col-2 text-end">
              <p className="m-2 p-2">2017 bis 2018 </p>
            </div>
            <div className="col-10 ">
              <p className="m-2 p-2">
                <strong>Zusammen wird es wunderbar</strong>
              </p>
              <p className="m-2 p-2">
                Inklusives Musical für Schüler*innen von{" "}
                <a
                  href=" https://www.sachsen.schule/~fsgb2dd/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Robinsonschule
                </a>{" "}
                und{" "}
                <a
                  href="https://www.hskd.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none clickable"
                >
                  Heinrich-Schütz-Konservatorium Dresden
                </a>.{" "}
                Idee, Textdichtung, Dramaturgie und Komposition im Team mit
                Studierenden der HfM Dresden. Einstudierung und Produktion durch
                das Kollegium und die (fast) ganze Schüler*innenschaft der
                Robinsonschule, ergänzt durch Schüler*innen der 14. Grundschule.
                Arrangement, Einstudierung des Musicalorchesters am
                Heinrich-Schütz-Konservatorium und musikalische Gesamtleitung:
                Elke Reichel
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
