export default function Impressum() {
  return (
    <>
      <h2 className="m-2 p-2">Impressum</h2>

      <h4 className="m-2 p-2">Ansprechpartner</h4>
      <p className="m-2 p-2">Angaben gemäß § 5 TMG:</p>

      <ul className="justify-content-end list-unstyled m-2 p-2">
        <li>Elke Reichel</li>
        <li>
          <a
            href="mailto:elke-reichel@freenet.de"
            className="text-decoration-none clickable"
          >
            elke-reichel@freenet.de
          </a>
        </li>
        <li>+49 351 31234557</li>
        <li>Barteldesplatz 1</li>
        <li> 01309 Dresden</li>
      </ul>
      <h4 className="m-2 p-2">
        Verbraucherstreitbeilegung / Universalschlichtungsstelle
      </h4>
      <p className="m-2 p-2">
        Ich bin nicht bereit oder verpflichtet, an Streitbelegungsverfahren vor
        einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <h4 className="m-2 p-2">Haftung für Links</h4>

      <p className="m-2 p-2">
        Mein Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt er Verlinkung
        auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
        Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
        Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
        einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werde ich diese Links entfernen.
      </p>
      <h5 className="m-2 p-2">Urheberrecht</h5>

      <p className="m-2 p-2">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde
        ich derartige Inhalte umgehend entfernen.
      </p>

      {/*  <h4 class="m-2 p-2">Bilderrechte</h4>

        <p class="text">
          ich weise hiermit auf die Bildplattformen der verwendeten
          lizenzfreien Bilder / Icons hin und bedanken uns für das Bildmaterial.
        </p> */}
    </>
  );
}
